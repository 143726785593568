let isLeadRequestInProgress = false;
let lastLeadRequestTime = 0;
const DEBOUNCE_TIME = 2000; // 2 seconds

export const setupRequestInterceptor = () => {
    const originalFetch = window.fetch;
    
    window.fetch = async (input: RequestInfo | URL, init?: RequestInit) => {
        const url = typeof input === 'string' 
            ? input 
            : input instanceof URL 
                ? input.toString() 
                : (input as Request).url;
        
        if (url.includes('/leads') && init?.method === 'POST') {
            const currentTime = Date.now();
            
            if (isLeadRequestInProgress || (currentTime - lastLeadRequestTime < DEBOUNCE_TIME)) {
                console.log('Preventing duplicate lead request');
                return new Response(JSON.stringify({ message: 'Request already in progress' }), {
                    status: 200,
                    headers: { 'Content-Type': 'application/json' }
                });
            }
            
            isLeadRequestInProgress = true;
            lastLeadRequestTime = currentTime;
            
            try {
                const response = await originalFetch(input, init);
                return response;
            } finally {
                isLeadRequestInProgress = false;
            }
        }
        
        return originalFetch(input, init);
    };
}; 