interface IContext {
    leadId: string;
    leadData: Record<string, string>;
}

export const generateNewLeadEmail = ({ leadData, leadId }: IContext) => {
    return `<html lang="en">
    <head>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <title>New Lead</title>
        <style type="text/css">
            body,
            table,
            td,
            a {
                -webkit-text-size-adjust: 100%;
                -ms-text-size-adjust: 100%;
            }

            table,
            td {
                mso-table-lspace: 0pt;
                mso-table-rspace: 0pt;
            }

            body {
                margin: 0;
                padding: 0;
                font-family: "Arial", sans-serif !important;
                mso-line-height-rule: exactly;
            }
        </style>
    </head>
    <body>
        <div id=":rt" class="a3s aXjCH">
            <u></u>
            <div marginwidth="0" marginheight="0">
                <div id="m_-6798059765791806010wrapper" dir="ltr" style="background-color: #f7f7f7; margin: 0; padding: 70px 0 70px 0; width: 100%">
                    <table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%">
                        <tbody>
                            <tr>
                                <td align="center" valign="top">
                                    <div id="m_-6798059765791806010template_header_image"></div>
                                    <table
                                        border="0"
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="600"
                                        id="m_-6798059765791806010template_container"
                                        style="background-color: #ffffff; border: 1px solid #dedede; border-radius: 3px; font-size: 16px"
                                    >
                                        <tbody>
                                            <tr>
                                                <td align="center" valign="top">
                                                    <table
                                                        border="0"
                                                        cellpadding="0"
                                                        cellspacing="0"
                                                        width="600"
                                                        id="m_-6798059765791806010template_header"
                                                        style="
                                                            background-color: #f7f7f7;
                                                            color: #ffffff;
                                                            border-bottom: 0;
                                                            font-weight: bold;
                                                            line-height: 100%;
                                                            vertical-align: middle;
                                                            font-family: Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
                                                            border-radius: 3px 3px 0 0;
                                                        "
                                                    >
                                                        <tbody>
                                                            <tr>
                                                                <td align="center" valign="top">
                    
                                                                <table border="0" cellpadding="0" cellspacing="0" width="600"
                                                                    id="m_-6798059765791806010template_header"
                                                                    style="background-color:#f7f7f7;color:#ffffff;border-bottom:0;font-weight:bold;line-height:100%;vertical-align:middle;font-family:Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;border-radius:3px 3px 0 0">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td id="m_-6798059765791806010header_wrapper"
                                                                                style="padding:36px 48px;display:block" align="center">
                                                                                <img src="https://carybit.com/wp-content/uploads/2022/08/Logo-Dark.png" width="256">
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                    
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    valign="top"
                                                    style="
                                                        border: 2rem solid #fff;
                                                        border-top: 0;
                                                        text-align: left;
                                                        font-family: 'Arial', sans-serif !important;
                                                    "
                                                >
                                                    <p>
                                                        <br /><br/>Great news! Someone has just filled out a lead form for Giver Marketing Network.
                                                    </p>
                                                </td>
                                            </tr>

                                            <tr style="height: 10px"></tr>

                                            <tr>   
                                                <td align="center" valign="top">
                                                    <table align="center" style="margin-bottom: 30px;">
                                                        <tr>
                                                        <td align="center">
                                                            <a href="https://admin.carybit.com/leads/${leadId}" target="_blank" style="background-color: #0278bc; color: #fff; padding: 14px 25px; text-align: center; text-decoration: none; display: inline-block; font-size: 16px; margin: 4px 2px; cursor: pointer; border-radius: 3px;">
                                                                View Lead Details
                                                            </a>
                                                        </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>

                                            <tr style="height: 20px"></tr>

                                            <tr>
                                                <td align="center" valign="top">
                                                    <table border="1" cellspacing="0" cellpadding="10" style="width: 100%; border-collapse: collapse;">
                                                        <thead>
                                                            <tr>
                                                                <th colspan="2" style="background-color: #0278bc; color: #fff; text-align: center;font-family: 'Arial', sans-serif !important;">Lead Details</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            ${Object.keys(leadData).map(key => `
                                                                <tr>
                                                                    <td style="width: 50%; padding: 20px;font-family: 'Arial', sans-serif !important;">${key}</td>
                                                                    <td style="width: 50%; padding: 20px;font-family: 'Arial', sans-serif !important;">${leadData[key]}</td>
                                                                </tr>`
                                                            ).join("")}
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>

                                            <tr style="height: 20px"></tr>

                                            <tr>
                                                <td
                                                    valign="top"
                                                    style="
                                                        border: 1rem solid #fff;
                                                        text-align: center;
                                                        font-family: 'Arial', sans-serif !important;
                                                        font-size: 14px;
                                                    "
                                                >
                                                    © Carybit LLC
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </body>
</html>
    
    `
};