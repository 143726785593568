import "../assets/scss/styles.scss";
import "@carybit/lead-generation-form/dist/index.css";
import configFile from "../../config.json";
import { LeadGenerationForm, IConfig } from "@carybit/lead-generation-form";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { MarketerForm } from "./MarketerForm";
import { ClientForm } from "./ClientForm";

const MainComponent = () => {
    const [config, setConfig] = useState<IConfig>(configFile as any);
    const location = useLocation();
    const [embed, setEmbed] = useState(false);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);

        const embedValue = queryParams.get("embed");

        const isEmbed = embedValue === "true";

        setEmbed(isEmbed);
    }, [location.search]);

    return (
        <LeadGenerationForm
            config={{ ...config, isEmbed: embed }}
            additionalPages={{
                "marketerForm": <MarketerForm config={config} />,
                "clientForm": <ClientForm config={config} />,
            }}
            onAnswerSelected={{
                "6496a394d64fbb65ee41041c": () => {
                    setConfig({
                        ...config,
                        orderedPages: config.orderedPages.map((page) => 
                            page === "clientForm" ? "marketerForm" : page
                        ),
                        questionOverwrites: { "6496a396d64fbb65ee410437": "How do you generate the most revenue for your clients?" },
                    });
                },
                "6496a394d64fbb65ee41041f": () => {
                    setConfig({
                        ...config,
                        orderedPages: config.orderedPages.map((page) => 
                            page === "marketerForm" ? "clientForm" : page
                        ),
                        questionOverwrites: { "6496a396d64fbb65ee410437": "How do you generate the most revenue for your business?" },
                    });
                },
            }}
        />
    );
};

export default MainComponent;
