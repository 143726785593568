import "../assets/scss/styles.scss";
import "@carybit/lead-generation-form/dist/index.css";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainComponent from './MainComponent';
import { setupRequestInterceptor } from '../utils/requestInterceptor';
import { useEffect } from 'react';

const App = () => {
    useEffect(() => {
        setupRequestInterceptor();
    }, []);

    return (
        <Router>
            <Routes>
                <Route path="/" element={<MainComponent />} />
            </Routes>
        </Router>
    );
};

export default App;
