import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form, IFormField, IConfig, ILead, Api } from "@carybit/lead-generation-form";
// import { generateNewLeadEmail } from "../assets/emails/new-lead";
import { MappedPage } from "@carybit/lead-generation-form/dist/types";
import { generateNewLeadEmail } from "../assets/emails/new-lead";

interface IProps {
    hidden?: boolean;
    api?: Api;
    updateLead?: (lead: ILead) => void;
    lead?: ILead;
    answers?: Record<string, string[]>;
    openAnswers?: Record<string, string>;
    nextPage?: () => void;
    pages?: MappedPage[];
    config: IConfig;
}

export const ClientForm = ({
    hidden,
    updateLead,
    nextPage,
    api,
    lead,
    answers,
    openAnswers,
    pages,
    config,
}: IProps) => {
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [error, setError] = useState<string>(null);

    const [fields, setFields] = useState<IFormField[]>([
        { name: "Name", type: "text", id: "name", isRequired: true, placeholder: "John Doe" },
        { name: "Email", type: "text", id: "email", isRequired: true, placeholder: "john.doe@mail.com" },
        { name: "Phone", type: "text", id: "phone", isRequired: true, placeholder: "+1 234-567-8910" },
        { name: "Company", type: "text", id: "company", isRequired: true, placeholder: "Your Company Inc." },
        { name: "Website", type: "text", id: "website", isRequired: false, placeholder: "www.website.com" },
        { name: "LinkedIn", type: "text", id: "linkedin", isRequired: false, placeholder: "Profile URL" },
    ]);

    const form = useForm();

    const handleSubmit = async () => {
        const values = form.getValues();

        setError(null);

        updateLead({
            name: values.name,
            email: values.email,
            phone: values.phone,
            company: values.company,
            website: values.website,
            customProperties: {
                LinkedIn: values.linkedin,
            },
        });

        if (!isSubmitted) {
            const leadData = {
                openAnswers,
                answers,
                lead: {
                    ...lead,
                    name: values.name,
                    phone: values.phone,
                    email: values.email,
                    company: values.company,
                    website: values.website,
                    customProperties: {
                        LinkedIn: values.linkedin,
                    },
                },
            };

            let response: ILead;

            const leadDataEmail = {
                "Name": values.name,
                "Email": values?.email,
                "Phone Number": values?.phone,
                "Company": values?.company,
                "Website": values?.website,
                'LinkedIn': values?.linkedin,
            };

            pages
                .filter(
                    (page) =>
                        (Object.keys(answers).includes(page._id) &&
                            (typeof answers[page._id] === "object" ? (answers[page._id] as string[]).length > 0 : !!answers[page._id]?.toString())) ||
                        (Object.keys(openAnswers).includes(page._id) && openAnswers[page._id] !== ""),
                )
                .map((page, index) => {
                    const answer =
                        answers[page._id] && typeof answers[page._id] === "object"
                            ? page.answers
                                  .filter((answer) => (answers[page._id] as string[]).includes(answer._id))
                                  .map((answer) => answer.text)
                                  .join("/")
                            : answers[page._id]
                            ? answers[page._id]?.toString()
                            : openAnswers[page._id];

                    leadDataEmail[page.text] = answer;
                });

            try {
                response = await api.createLead(leadData);

                nextPage();

                if (response._id) {
                    localStorage.setItem("leadId", response._id);
                }
            } catch (error) {
                console.error("Failed to create lead", error);
            } finally {
                setIsSubmitted(true);

                if (response?._id) {
                    await api.sendEmail({
                        userId: config.variables.userId,
                        to: config.variables.notificationsEmail,
                        body: generateNewLeadEmail({ leadData: leadDataEmail, leadId: response?._id }),
                        subject: `New Lead: ${values?.name}`,
                    });

                    setTimeout(async () => {
                        await api.sendEmail({
                            userId: config.variables.userId,
                            to: config.variables.notificationsEmail2,
                            body: generateNewLeadEmail({ leadData: leadDataEmail, leadId: response?._id }),
                            subject: `New Lead: ${values?.name}`,
                        });
                    }, 1000);
                }
            }
        }
    };

    const validate = () => {
        const values = form.watch();

        if (!values.name || !values.phone || !values.email) {
            return setIsFormValid(false);
        }

        setIsFormValid(true);
    };
    useEffect(() => {
        const subscription = form.watch((_, { name }) => {
            if (name === "phone" || name === "name" || name === "email") {
                validate();
            }
        });

        return () => subscription.unsubscribe();
    }, [form]);

    return (
        <div className="session-form email-form">
            <Form
                title={"Your Final Step"}
                buttonText={"Continue"}
                form={form}
                hidden={hidden}
                fields={fields}
                onSubmit={handleSubmit}
                isSubmitHidden={!isFormValid}
                isButtonVisible={false}
                error={error}
            />
        </div>
    );
};
